define("discourse/plugins/discourse-rss-polling/discourse/controllers/admin-plugins-rss-polling", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/utils", "discourse-common/utils/decorators", "discourse/plugins/discourse-rss-polling/admin/models/rss-polling-feed-settings"], function (_exports, _controller, _object, _computed, _utils, _decorators, _rssPollingFeedSettings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    feedSettings: (0, _computed.alias)("model"),
    saving: false,
    valid: false,
    unsavable(valid, saving) {
      return !valid || saving;
    },
    validate() {
      let overallValidity = true;
      this.get("feedSettings").forEach(feedSetting => {
        const localValidity = !(0, _utils.isBlank)(feedSetting.feed_url) && !(0, _utils.isBlank)(feedSetting.author_username);
        (0, _object.set)(feedSetting, "valid", localValidity);
        overallValidity = overallValidity && localValidity;
      });
      this.set("valid", overallValidity);
    },
    actions: {
      create() {
        this.get("feedSettings").addObject({
          feed_url: null,
          author_username: null,
          discourse_category_id: null,
          discourse_tags: null,
          feed_category_filter: null
        });
      },
      destroy(feedSetting) {
        this.get("feedSettings").removeObject(feedSetting);
        this.send("update");
      },
      update() {
        this.set("saving", true);
        _rssPollingFeedSettings.default.update(this.get("feedSettings")).then(updatedSettings => {
          this.set("feedSettings", updatedSettings["feed_settings"]);
        }).finally(() => {
          this.set("saving", false);
        });
      },
      updateAuthorUsername(setting, selected) {
        (0, _object.set)(setting, "author_username", selected.firstObject);
      }
    }
  }, [["method", "unsavable", [(0, _decorators.default)("valid", "saving")]], ["method", "validate", [(0, _decorators.observes)("feedSettings.@each.{feed_url,author_username}")]]]));
});